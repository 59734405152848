<template>
	<div style="height: inherit">
		<div v-if="inboxItems.length === 0 && !loading">
			<Empty></Empty>
		</div>
		<!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
		<div v-else>
			<loading v-if="loading"></loading>
			<!-- Email List -->
			<div class="inbox-app-list" v-else>
				<b-row class="mx-15">
					<b-col>
						<h6 class="section-label mb-1">
							{{ $t('assignment.followSentAssignmentsAndNotifications') }}
						</h6>
					</b-col>
				</b-row>
				<b-row class="mx-15 mb-1">
					<b-col>
						<div class="d-flex align-content-end justify-content-end w-50">
							<b-form-input :value="searchQuery" :placeholder="$t('search')" v-model="search"/>
						</div>
					</b-col>
				</b-row>

				<!-- App Action Bar -->
				<div class="app-action">
					<div class="action-left">
						<!--            <b-form-checkbox-->
						<!--                :checked="selectAllItemsCheckbox"-->
						<!--                :indeterminate="isSelectAllItemsCheckboxIndeterminate"-->
						<!--                @change="selectAllCheckboxUpdate"-->
						<!--            >-->
						{{ $t('all') }}
						<!--            </b-form-checkbox>-->
					</div>
					<div v-show="selectedItems.length" class="align-items-center" :class="{'d-flex': selectedItems.length}">
						<!-- Update Mail Folder Dropdown -->
						<b-dropdown variant="link" no-caret toggle-class="p-0" right>
							<template #button-content>
								<feather-icon icon="FolderIcon" size="17" class="align-middle text-body"/>
							</template>

							<b-dropdown-item @click="moveSelectedMessagesToFolder('draft')">
								<feather-icon icon="Edit2Icon"/>
								<span class="align-middle ml-50">Draft</span>
							</b-dropdown-item>

							<b-dropdown-item @click="moveSelectedMessagesToFolder('spam')">
								<feather-icon icon="InfoIcon"/>
								<span class="align-middle ml-50">Spam</span>
							</b-dropdown-item>

							<b-dropdown-item v-show="$route.params.folder !== 'trash'" @click="moveSelectedMessagesToFolder('trash')">
								<feather-icon icon="TrashIcon"/>
								<span class="align-middle ml-50">Trash</span>
							</b-dropdown-item>
						</b-dropdown>

						<!-- Update Mail Folder Dropdown -->
						<b-dropdown variant="link" no-caret toggle-class="p-0" class="ml-1" right>
							<template #button-content>
								<feather-icon icon="TagIcon" size="17" class="align-middle text-body"/>
							</template>
							<b-dropdown-item @click="updateSelectedMessagesLabel('personal')">
								<span class="mr-50 bullet bullet-success bullet-sm"/>
								<span>Personal</span>
							</b-dropdown-item>
							<b-dropdown-item @click="updateSelectedMessagesLabel('company')">
								<span class="mr-50 bullet bullet-primary bullet-sm"/>
								<span>Company</span>
							</b-dropdown-item>
							<b-dropdown-item @click="updateSelectedMessagesLabel('important')">
								<span class="mr-50 bullet bullet-warning bullet-sm"/>
								<span>Important</span>
							</b-dropdown-item>
							<b-dropdown-item @click="updateSelectedMessagesLabel('private')">
								<span class="mr-50 bullet bullet-danger bullet-sm"/>
								<span>Private</span>
							</b-dropdown-item>
						</b-dropdown>
						<feather-icon icon="MailIcon" size="17" class="cursor-pointer ml-1" @click="markSelectedMessagesAsUnread"/>
						<feather-icon v-show="$route.params.folder !== 'trash'" icon="TrashIcon" size="17" class="cursor-pointer ml-1" @click="moveSelectedMessagesToFolder('trash')"/>
					</div>
				</div>

				<!-- Emails List -->
				<div class="inbox-user-list scroll-area">
					<ul class="inbox-media-list">
						<b-media v-for="item in filteredList" :key="item.id" tag="li" no-body :class="{ 'mail-read': item.read }" @click="updateMessageViewData(item)">
							<!--              <b-media-aside class="media-left">-->
							<!--                <b-form-checkbox-->
							<!--                    :checked="selectedItems.includes(item.id)"-->
							<!--                    @change="toggleSelectedMail(item.id)"-->
							<!--                    @click.native.stop-->
							<!--                />-->
							<!--              </b-media-aside>-->
							<b-media-aside class="media-left mr-50">
								<b-avatar class="avatar" size="40" variant="primary" :text="setInitials(item.receiver)"/>
							</b-media-aside>

							<b-media-body>
								<div class="mail-details">
									<div class="mail-items">
										<h5 class="mb-25">
											{{ 'To: ' + item.receiver }}
										</h5>
										<span class="text-truncate">{{ item.subject }}</span>
									</div>
									<div class="mail-meta-item align-content-center">
										<b-badge variant="light-warning" class="mx-50">
											{{ item.messageType.displayText }}
										</b-badge>
										<b-badge variant="light-warning" class="mx-50" v-if="item.status.name === 'CLOSED'">
											{{ item.status.displayText }}
										</b-badge>
										<b-button
											v-if="item.status.name === 'OPEN' && item.messageType.name === 'WAITING_FOR_REVIEW_MSG'"
											class="mx-50 py-50"
											variant="outline-primary"
											size="sm">
											{{ i18n.t('accept') }}
										</b-button>
										<feather-icon
											v-if="false"
											icon="PaperclipIcon"
										/>
										<span
											v-for="label in item.labels"
											:key="label"
											class="mx-50 bullet bullet-sm"
											:class="`bullet-${resolveLabelColor(label)}`"
										/>
										<span class="mail-date">
											{{
												formatDateToMonthShort(item.createdAt, {
													hour: 'numeric',
													minute: 'numeric',
												})
											}}
										</span>
									</div>
								</div>
							</b-media-body>
						</b-media>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {BFormInput, BDropdown, BDropdownItem, BRow, BCol, BMedia, BMediaAside, BMediaBody, BAvatar, BButton, BBadge} from 'bootstrap-vue'
import useInbox from './../useInbox'
import Loading from "@/views/components/Loading/Loading";
import {mapState} from "vuex";
import Empty from "@/views/Assignment/Closed/Empty";
import StringUtil from "@/services/StringUtil";

export default {
	name: "Closed",
	components: {
		BRow, BCol,
		BFormInput,
		BButton,
		BBadge,
		// BInputGroup,
		BDropdown,
		BDropdownItem,
		// BFormCheckbox,
		BMedia,
		BMediaAside,
		BMediaBody,
		BAvatar,
		Loading,
		Empty,
		// 3rd Party
	},
	computed: {
		...mapState({
			loading: state => state.assignment.sentLoading
		}),
		filteredList() {
			return this.inboxItems.filter(post => {
				return post.sender.toLowerCase().includes(this.search.toLowerCase()) || post.subject.toLowerCase().includes(this.search.toLowerCase());
			})
		}
	},
	setup() {
		const { // UI
			perfectScrollbarSettings,

			// Emails & EmailsMeta
			fetchInbox,
			fetchSent,
			fetchClosed,
			inboxItems,
			inboxItemMeta,

			// Mail Selection
			selectAllItemsCheckbox,
			isSelectAllItemsCheckboxIndeterminate,
			selectedItems,
			toggleSelectedMail,
			selectAllCheckboxUpdate,

			// Mail Actions
			toggleStarred,
			moveSelectedMessagesToFolder,
			updateSelectedMessagesLabel,
			markSelectedMessagesAsUnread,

			// Email Details
			showEmailDetails,
			emailViewData,
			opendedEmailMeta,
			updateMessageViewData,
			moveOpenEmailToFolder,
			updateOpenEmailLabel,
			markOpenEmailAsUnread,
			changeOpenedEmail,

			// Search Query
			searchQuery,
			updateClosedSearchQuery,
			updateRouteQuery,

			// UI Filters
			filterTags,
			formatDateToMonthShort,

			// useEmail
			resolveLabelColor,

		} = useInbox()

		fetchClosed()

		return {
			// UI
			perfectScrollbarSettings,

			// Emails & EmailsMeta
			fetchInbox,
			fetchSent,
			fetchClosed,
			inboxItems,
			inboxItemMeta,

			// Mail Selection
			selectAllItemsCheckbox,
			isSelectAllItemsCheckboxIndeterminate,
			selectedItems,
			toggleSelectedMail,
			selectAllCheckboxUpdate,

			// Mail Actions
			toggleStarred,
			moveSelectedMessagesToFolder,
			updateSelectedMessagesLabel,
			markSelectedMessagesAsUnread,

			// Email Details
			showEmailDetails,
			emailViewData,
			opendedEmailMeta,
			updateMessageViewData,
			moveOpenEmailToFolder,
			updateOpenEmailLabel,
			markOpenEmailAsUnread,
			changeOpenedEmail,

			// Search Query
			searchQuery,
			updateClosedSearchQuery,
			updateRouteQuery,

			// UI Filters
			filterTags,
			formatDateToMonthShort,

			// useEmail
			resolveLabelColor,
		}
	},
	data() {
		return {
			search: '',
		}
	},
	methods: {
		setInitials(text) {
			return StringUtil.setInitials(text)
		}
	}
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/inbox-page.scss";
</style>
